



















































import {
  computed,
  defineComponent,
  useContext,
  useRoute,
  useStore,
} from '@nuxtjs/composition-api'
import { IHolidayTheme } from '@/contracts/Settings'

const COMPONENTS = {
  Dummy: 'Dummy',
  sectionCheckBlanks: 'SectionCheckBlanks',
  sectionBanner: 'SectionBlockBannerText',
  sectionBannerList: 'SectionBannerList',
  sectionCardIcon: 'SectionCardIcon',
  sectionCardImage: 'SectionCardImage',
  sectionCardImageIcon: 'SectionCardImageIcon',
  sectionFaq: 'SectionFaq',
  sectionLinks: 'SectionLinksList',
  sectionCardRating: 'SectionCardRating',
  sectionImages: 'SectionImages',
  sectionText: 'SectionBlockText',
  sectionGrid: 'SectionGrid',
  sectionIframe: 'SectionIframe',
  sectionFaqNavigation: 'SectionFaqNavigation',
  sectionProductHero: 'SectionProductHero',
  sectionForm: 'SectionForm',
  sectionLeadDetail: 'SectionLeadDetail',
  sectionBlockBanner: 'SectionBlockBanner',
  sectionList: 'SectionList',
  sectionListExtended: 'SectionListExtended',
  sectionHeroSlider: 'SectionHeroSlider',
  sectionLeads: 'SectionLeads',
  sectionSubscribe: 'SectionSubscribe',
  sectionRequisites: 'SectionRequisites',
  sectionDocumentsList: 'SectionDocumentsList',
  sectionDocuments: 'SectionDocuments',
  sectionCardImageGrid: 'SectionCardImageGrid',
  sectionBlockTagRow: 'SectionBlockTagRow',
  sectionListArchive: 'SectionListArchive',
  sectionAllNews: 'SectionNews',
  sectionInstructionsTest: 'SectionInstructionsTest',
  sectionCardNews: 'SectionCardNews',
  sectionSearch: 'SectionSearch',
  sectionListAlphabet: 'SectionListAlphabet',
  sectionListBlockLinks: 'SectionListBlockLinks',
  sectionCardsInfo: 'SectionCardsInfo',
  sectionVacanciesHero: 'SectionVacanciesHero',
  sectionVacanciesList: 'SectionVacanciesList',
  sectionSearchVacancies: 'SectionSearchVacancy',
  sectionSearchVacanciesStartCareer: 'SectionSearchVacanciesStartCareer',
  sectionVacancy: 'SectionVacancy',
  sectionOffices: 'SectionOffices',
  sectionCheckAgents: 'SectionCheckAgents',
  sectionCheckStatus: 'SectionCheckStatus',
  sectionPretrial: 'SectionPretrial',
  sectionAppeal: 'SectionAppeal',
  sectionFeedbackLK: 'SectionFeedbackLK',
  sectionFeedbackDebtors: 'SectionFeedbackDebtors',
  sectionCheckDocuments: 'SectionCheckDocuments',
  sectionInsuranceCase: 'SectionInsuranceCase',
  sectionImageText: 'SectionImageText',
  sectionCardsNumber: 'SectionCardsNumber',
  sectionVideo: 'SectionVideo',
  sectionSTOA: 'SectionSTOA',
  sectionListTables: 'SectionListTables',
  sectionListOffices: 'SectionListCardTileOffice',
  sectionQuiz: 'SectionQuiz',
  sectionHeroVip: 'SectionHeroVip',
  sectionPopular: 'SectionPopular',
  sectionCustom: 'SectionCustom',
  sectionAchievements: 'SectionAchievements',
  sectionChildrenSport: 'SectionChildrenSport',
  sectionReviews: 'SectionReviews',
  sectionImageTextCard: 'SectionImageTextCard',
  sectionCardsTitleAndIcon: 'SectionCardsTitleAndIcon',
  sectionCardsTitleDescriptionImage: 'SectionCardsTitleDescriptionImage',
  sectionSteps: 'SectionSteps',
  sectionAgentsFeedback: 'SectionAgentsFeedback',
  sectionGridAdvantages: 'SectionGridAdvantages',
  sectionOsagoHelp: 'SectionOsagoHelp',
  sectionOsagoNumber: 'SectionOsagoNumber',
  sectionOsagoInfo: 'SectionOsagoInfo',
  sectionOsagoHero: 'SectionOsagoHero',
  sectionTabs: 'SectionTabs',
  sectionButtons: 'SectionButtons',
  sectionTextActions: 'SectionTextActions',
  sectionPulseGrid: 'SectionPulseGrid',
  sectionImageBackground: 'SectionImageBackground',
  sectionPulseForm: 'SectionPulseForm',
  sectionAlternateSlider: 'SectionAlternateSlider',
  sectionServices: 'SectionServices',
  sectionPromocodeBanner: 'SectionPromocodeBanner',
}

const loadComponent = (component: string) => {
  return () => {
    return import(
      /* webpackChunkName: "components" */ `@/views/${
        (COMPONENTS as any)[component]
      }`
    )
  }
}

export default defineComponent({
  name: 'Mediator',
  props: {
    components: {
      type: Array,
      default: () => [],
    },
    hideContent: {
      type: Boolean,
      default: false,
    },
    hideDelimiters: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const context = useContext()
    const route = useRoute()
    const store = useStore()
    const isDebugTemplatesShown = computed(
      () => context.$config.IS_DEBUG_TEMPLATES_SHOWN ?? false
    )
    const holidayTheme = computed<IHolidayTheme>(
      () => store.getters['settings/holidayTheme']
    )

    const getAppealForm = () => {
      const appealComponent = props.components.find(
        (c) => c.type === 'sectionAppeal'
      )
      const feedbackLKComponent = props.components.find(
        (c) => c.type === 'sectionFeedbackLK'
      )

      const formTypeQueryParam = route.value.query?.formtype

      if (
        route.value.path === '/feedback' ||
        route.value.path === '/insurance-case/obrashchenie-v-rossgostrakh'
      ) {
        if (formTypeQueryParam === 'dms') {
          return [
            {
              ...feedbackLKComponent,
              name: 'sectionFeedbackLK',
              compFunc: loadComponent('sectionFeedbackLK'),
            },
          ]
        }

        return [
          {
            ...appealComponent,
            name: 'sectionAppeal',
            compFunc: loadComponent('sectionAppeal'),
          },
        ]
      }

      return null
    }

    const parsedComponents = computed(() => {
      const components = getAppealForm()

      if (components) {
        return components
      }

      const currentComponents = (props.components as any).filter(
        (item: any) => item.type !== 'skip'
      )

      if (!props.components) return []

      const finalComponent = currentComponents.reduce(
        (components: any, component: any) => {
          const { type = 'Dummy' } = component || {}

          const name = type in COMPONENTS ? type : 'Dummy'

          const componentMapped = {
            ...component,
            name,
            compFunc: loadComponent(name),
          }

          return type === 'dummy' &&
            context.$config.IS_DEBUG_TEMPLATES_SHOWN === 'false'
            ? components
            : [...components, componentMapped]
        },
        []
      )

      return props.hideContent &&
        !!finalComponent.find((component) => component.name === 'sectionIframe')
        ? finalComponent.filter(
            (component) => component.name === 'sectionIframe'
          )
        : finalComponent
    })

    return {
      parsedComponents,
      isDebugTemplatesShown,
      holidayTheme,
    }
  },
})
